import './index.scss'
// import '../../assets/scripts/myNiceScroll'
// import {resizeScroll} from '../../assets/scripts/myNiceScroll'
import '@fancyapps/fancybox/dist/jquery.fancybox.min'
import Swiper from 'swiper/dist/js/swiper.min.js'

var indexswiper = new Swiper(".index-banner .mySwiper", {
  speed: 1000,
  loop: true,
  autoplay: {
    delay: 15000,
    stopOnLastSlide: false,
    disableOnInteraction: false,
    waitForTransition: false,
  },
  // breakpoints: {
  //   1024: { 
  //     autoplay: {
  //       delay: 6000,
  //       stopOnLastSlide: false,
  //       disableOnInteraction: false,
  //       waitForTransition: false,
  //     },
  //   }
  // },
  pagination: {
    el: ".index-banner .swiper-pagination",
    clickable: true
  },
  navigation: {
    nextEl: ".index-banner .swiper-button-next",
    prevEl: ".index-banner .swiper-button-prev",
  },
  on: {
    init: function(swiper){
      $('.index-banner .swiper-mypagination .totalNum').html(this.slides.length - 2)
    },
    slideChangeTransitionStart: function(){
      $('.index-banner .swiper-mypagination .activeNum').html(this.realIndex + 1)
    },
  },
});

var solutionswipertext = new Swiper(".section2 .right .mySwiper", {
  allowTouchMove: false,
  speed: 800,
  loop: true,
  // autoHeight: true,
  spaceBetween : 20,
  pagination: {
    el: ".section2 .left .swiper-pagination",
    clickable: true
  },
});
var solutionswiperimg = new Swiper(".section2 .left .mySwiper", {
  speed: 800,
  loop: true,
  autoplay: {
    delay: 4000,
    stopOnLastSlide: false,
    disableOnInteraction: false,
    waitForTransition: false,
  },
  pagination: {
    el: ".section2 .left .swiper-pagination",
    clickable: true
  },
  on: {
    slideChangeTransitionStart: function(){
      solutionswipertext.slideTo(this.activeIndex)
    },
  },
});

var proswiperimg = new Swiper(".section3 .right .mySwiper", {
  speed: 800,
  loop: true,
  autoplay: {
    delay: 4000,
    stopOnLastSlide: false,
    disableOnInteraction: false,
    waitForTransition: false,
  },
  pagination: {
    el: ".section3 .right .swiper-pagination",
    clickable: true
  },
});


var newswiperimg = new Swiper(".section4 .left .mySwiper", {
  speed: 800,
  loop: true,
  autoplay: {
    delay: 4000,
    stopOnLastSlide: false,
    disableOnInteraction: false,
    waitForTransition: false,
  },
  pagination: {
    el: ".section4 .left .swiper-pagination",
    clickable: true
  },
});

$(document).scroll(function () {
  let scroH = $(document).scrollTop(); //婊氬姩楂樺害
  let viewH = $(window).height(); //鍙楂樺害
  let contentH = $(document).height(); //鍐呭楂樺害

  if (scroH > 10) {
    $('.section1 .index-banner').addClass('scroll')
  }else{
    $('.section1 .index-banner').removeClass('scroll')
  }
  if (contentH - (scroH + viewH) <= 100) { 
    //璺濈搴曢儴楂樺害灏忎簬100px
  }
  if (contentH == (scroH + viewH)) { 
    //婊氬姩鏉℃粦鍒板簳閮ㄥ暒
  }
});

$(document).ready(function(){
  // 瑙嗛鐩稿叧
  var $video = $('.index-banner video')
  $video.each(function(index){
    this.addEventListener('play', function () { //鎾斁寮€濮嬫墽琛岀殑鍑芥暟
      $(this).closest('.video').find('.video-play').hide()
    });
    this.addEventListener('pause', function () { //鏆傚仠寮€濮嬫墽琛岀殑鍑芥暟
      $(this).closest('.video').find('.video-play').show()
    });
  })
  $('.index-banner .video .video-play').on('click',function(){
    let $parent = $(this).closest('.video')
    $parent.find('video')[0].play()
    $(this).hide()
  })
})